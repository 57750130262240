$ifaBlue: #146c90;
$ifaLightBlue: #5da3bf;
$ifaBlueBorder: rgba(40,140,181,.5);
$ifaDarkBlue: #313e44;
$ifaGreen:#006600;
$ifaGreenHover:#090;
$ifaGrey: #ccc;
$ifaLightGray:#ebebeb;
$ifaBackgroundGray: #f6f6f6;
$ifaBody: #333;
$ifaBodyBackground: #f5f5f7;
$ifaDarkGrey:#353737;
$white: #fff;
$empty: #C0C7DE;
$black: #000000;

$ifaFontFamilyHeading:'Bree Serif', serif;
:root 
{
    --blue: #00b;
    $ifaBlue: rgba(0, 102, 153, 0.8);
    $ifaGreen:$ifaGreen;
}



