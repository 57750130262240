
@media print {          
   .navigation-bar,
   #Embed,
   #launcher,
   .dropdown-toggle,
   .nav,
   .sticky-top,
   app-article-search-form,
   .social-icons,
   .row.attribution,
   .tags,
   .endOfArticleNavigation,
   app-related-content,
   #products,
   .subnav  {
    display: none !important;
    }
    html,body {
        width: 1100px;
        padding-right:20px
    }
    .jumbotron {
        color:#000;
        background-image: none;
        min-height: auto !important;
        
    } 
    app-jumbotron{
        box-shadow: none !important;
        background-image: none !important;
    }
   
 }